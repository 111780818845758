@import '../variables.scss';

.Legend {
  background-color: var(--ui-background);
  border-radius: var(--border-radius-small);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: var(--standard-space);

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    text-transform: capitalize;
  }

  &-toggle-button {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  svg {
    background: white;
  }

  .highlighted-bar-count,
  .highlighted-bar-extent {
    font-size: 0.8em;
    text-transform: uppercase;
  }

  .visible-bars {
    pointer-events: none;
  }

  .visible-bars rect.highlighted {
    stroke: gray;
  }

  .x-axis .domain {
    display: none;
  }
}
