@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import './variables.scss';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--standard-text-color);
  font-size: var(--text-size-normal);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#react-tiny-popover-container {
  z-index: 50;
}
.menu-header-label {
  margin-bottom: var(--standard-space);
}

.menu-primary-label {
  font-weight: bold;
  margin-bottom: var(--standard-space);
}

.menu-secondary-label {
  margin-bottom: calc(var(--standard-space) / 2);
}

.drop-shadow {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}
