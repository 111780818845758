@import '../../variables.scss';

.TableFilterMenu {
  display: flex;
  flex-direction: column;
  z-index: 40;
  width: 100%;
  background-color: var(--ui-background);
  border: var(--border-width) solid var(--secondary-gray);
  border-radius: 6px;
  padding: var(--standard-space);
  width: 330px;
  box-shadow: 0px 0px 12px 0px #0000001a;

  &-header {
    display: flex;
    align-items: baseline;
    gap: 6px;
    margin-bottom: var(--standard-space);

    &-label {
      font-size: 18px;
    }

    &-reset-label {
      font-size: 12px;
      color: var(--orange);
      gap: 6px;
      cursor: pointer;
    }
  }

  &-section {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--standard-space);

    &-header-label {
      font-size: 14px;
      font-weight: 500;
      color: var(--standard-text-color);
      margin-bottom: 6px;
      text-transform: uppercase;
    }
  }

  &-close-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
  }
}
