@import './variables.scss';

html {
  height: 100%;
  width: 100%;
  font-size: 14px;
}

body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &-map-loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: var(--loader-color-background);
  }

  &-layout {
    flex-grow: 1;
    width: 100%;
    display: flex;
    position: relative;

    &-main {
      flex-grow: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &-maps-container {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;
    }

    &-map-container {
      flex-grow: 1;
      height: 100%;

      &.border {
        border-right: 3px solid black;
      }
    }

    &-right-fixed {
      top: 0;
      right: 0;
      position: absolute;
      margin: var(--standard-space);
      display: flex;
      max-height: calc(100% - var(--standard-space) * 2);
    }

    &-upper-left-fixed {
      top: calc(var(--map-zoom-container-height) + 4.75rem);
      left: 0;
      position: absolute;
      margin: 1.75rem;
      display: flex;
      flex-direction: column;
    }

    &-map-zoom-container {
      margin-bottom: var(--standard-space);
    }

    &-main-menu-container {
      display: flex;
    }

    &-lower-left-fixed {
      bottom: 0;
      left: 0;
      position: absolute;
      margin: 1.75rem;
      margin-left: 60px;
      padding: var(--standard-space);
    }
  }

  &-origin-destination-container {
    margin-top: var(--standard-space);
    background-color: var(--ui-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--standard-space);
    height: var(--origin-destination-toggle-height);
    min-width: 360px;
    border-radius: var(--border-radius-small);

    &-label {
      margin-bottom: unset;
      margin-right: var(--standard-space);
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  }

  &-legend-container {
    margin-bottom: var(--standard-space);
  }

  &-data-menu-container {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: var(--standard-space);
    display: flex;
    justify-content: flex-end;
  }

  &-transit {
    &-trips-section {
      width: 100%;
      height: var(--transit-collapsed-height);
      box-shadow: 0px -1px 10px var(--loader-color-background);
      border-top: var(--border-width) solid var(--secondary-gray);
      z-index: 20;
      position: relative;

      // This is intended to keep the map in place while trips are expanded
      &-placeholder {
        width: 100%;
        height: var(--transit-collapsed-height) !important;
        box-shadow: 0px -1px 10px var(--loader-color-background);
        border-top: var(--border-width) solid var(--secondary-gray);
        z-index: 1;
        position: relative;
      }

      &.expanded {
        height: 90vh !important;
        max-height: unset;
        position: fixed;
        bottom: 0;
        z-index: 20;
      }

      &.collapsed {
        height: 5vh !important;
        max-height: unset;
        position: fixed;
        bottom: 0;
        z-index: 20;
      }

      &-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;

        &-container {
          position: absolute;
          height: 20px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: end;
          top: -19px;
          background-color: var(--unselected-gray);
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
          border-top: var(--border-width) solid var(--secondary-gray);
          border-left: var(--border-width) solid var(--secondary-gray);
          border-right: var(--border-width) solid var(--secondary-gray);
          cursor: pointer;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          gap: 2px;
        }
      }
    }

    &-trips-content {
      display: flex;
      height: calc(100% - 2 * var(--standard-space));
      width: calc(100% - 2 * var(--standard-space));
      background-color: var(--unselected-gray);
      flex-direction: row-reverse;
      gap: var(--standard-space);
      padding: var(--standard-space);

      &-trips-display-column {
        display: flex;
        width: calc(65% - var(--standard-space) * 0.5);
        height: 100%;
      }

      &-scatterplot-column {
        display: flex;
        width: calc(35% - var(--standard-space) * 0.5);
        height: 100%;
        flex-direction: column;
        gap: var(--standard-space);
      }
    }
  }
}
