:root {
  // Colors
  --ui-background: #fff;
  --selected-color: #5db1e8;
  --selected-color-hover: #17a1fc;
  --loader-color: #17a1fc;
  --loader-color-opacity: rgba(0, 179, 255, 0.3);
  --loader-color-background: rgba(0, 0, 0, 0.3);
  --secondary-gray: #dbdfe1;
  --red: #dd2727;
  --unselected-gray: #f9f9f9;
  --medium-gray: #899aa3;
  --standard-text-color: #3f4d55;
  --drop-shadow: rgba(63, 77, 85, 0.5);
  --graph-background: #f4fafd;
  --secondary-dark: #5a6c76;
  --gray-light-background: var(--unselected-gray);
  --orange: #f88113;
  --selected-low-opacity-color: rgba(93, 177, 232, 0.1);
  --blue-dark: #148bcc;
  --warning-color: #ea3e3e;

  // Alter SVG colors
  --filter-ui-background: brightness(0) saturate(100%) invert(100%) sepia(0%)
    saturate(7500%) hue-rotate(307deg) brightness(102%) contrast(102%);

  // SVG filters
  --filter-svg-medium-gray: brightness(0) saturate(100%) invert(58%) sepia(16%)
    saturate(285%) hue-rotate(156deg) brightness(101%) contrast(90%);

  // Spacing
  --standard-space: 0.9rem;
  --dropdown-spacing: 0.5rem;

  // Buttons
  --button-padding: 0.5rem;
  --button-border-radius: 0.25rem;
  --button-margin: 0.5rem;

  // Borders
  --border-radius-large: 1rem;
  --border-radius-small: 0.5rem;
  --border-width: 1px;

  // Sizes
  --dropdown-height: 2rem;
  --max-dropdown-list-height: 300px;
  --menu-min-width: 300px;
  --menu-max-width: 360px;
  --graph-height: 0.5rem;
  --origin-destination-toggle-height: 2rem;
  --map-zoom-container-height: 15rem;
  --transit-collapsed-height: 500px;
  --transit-travel-time-height: 250px;

  // Text sizes
  --text-size-large: 1.2rem;
  --text-size-normal: 1rem;
  --text-size-small: 0.85rem;
  --text-size-extra-small: 0.75rem;
}
