@import '../../variables.scss';

.Stats {
  position: absolute;
  width: 140px;
  background: white;
  height: 100%;
  right: 0px;
  color: var(--standard-text-color);
  padding: 12px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  box-shadow: 0px 0px 12px 0px #0000001a;

  &-value {
    font-size: 15px;
    font-weight: 700;
  }

  &-label {
    font-size: 12px;
    font-weight: 500;
  }
}
