@import '../../variables.scss';

.Transitbreakdown {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;

  &-list {
    display: flex;
    gap: var(--standard-space);
    background-color: var(--ui-background);

    padding-left: var(--standard-space);
    padding-right: var(--standard-space);
    padding-bottom: var(--standard-space);
    flex-grow: 1;
    overflow: scroll;
    position: relative;
    border-bottom-left-radius: var(--border-radius-large);
    border-bottom-right-radius: var(--border-radius-large);
    border: var(--border-width) solid var(--secondary-gray);
    border-top: none;

    &-mode {
      display: flex;
      width: 100%;
      justify-content: end;
      gap: 3px;
      position: sticky;
      top: 0;
      background: var(--ui-background);

      @media screen and (max-width: 1225px) {
        min-width: 450px;
      }

      &-column {
        border: var(--border-width) solid var(--secondary-gray);
        border-radius: 30px;
        padding: 4px;
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4px;
        gap: 2px;

        &-indicator {
          width: 12px;
          height: 12px;
          border-radius: 12px;
        }

        &-label {
          white-space: nowrap;
          font-size: 11px;
          color: var(----standard-text-color);
        }
      }
    }

    &-column {
      display: flex;
      height: fit-content;
      flex-grow: 1;
      min-width: 140px;
      gap: 3px;

      &.mode {
        min-width: 40%;
      }

      &-header {
        position: sticky;
        top: 0;
        background-color: var(--ui-background);
        padding-top: var(--dropdown-spacing);
        padding-bottom: var(--dropdown-spacing);
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: var(--button-margin);
        white-space: nowrap;
        flex-shrink: 0;
        z-index: 1;
      }
    }

    &-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-top: var(--standard-space);
      gap: 2px;

      &-label {
        height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      &-breakdown {
        width: 100%;
        display: flex;
        height: 24px;
        @media screen and (max-width: 1225px) {
          min-width: 450px;
        }

        &-label {
          height: 22px;
          padding: 2px;
          display: flex;
          align-items: center;
          color: #000000;
          font-size: 11px;
          font-weight: 500;
        }
      }
    }

    &-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &.unhovered {
        opacity: 0.3;
      }
    }
  }
}
