@import '../variables.scss';

.Layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &-map-loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: var(--loader-color-background);
  }

  &-layout {
    flex-grow: 1;
    width: 100%;
    display: flex;
    position: relative;

    &-map-container {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &-right-fixed {
      top: 0;
      right: 0;
      position: absolute;
      margin: var(--standard-space);
      display: flex;
      max-height: calc(100% - var(--standard-space) * 2);
    }

    &-upper-left-fixed {
      top: 0;
      left: 0;
      position: absolute;
      margin: 1.75rem;
      display: flex;
      flex-direction: column;
    }

    &-map-zoom-container {
      margin-bottom: var(--standard-space);
      height: var(--map-zoom-container-height);
    }

    &-main-menu-container {
      display: flex;
    }

    &-lower-left-fixed {
      bottom: calc(var(--origin-destination-toggle-height) + 1.75rem);
      left: 0;
      position: absolute;
      margin: 1.75rem;
      margin-left: 60px;
      padding: var(--standard-space);

      &.transit {
        bottom: unset;
        left: unset;
        top: 0;
        right: 0;
      }

      &.right {
        right: 140px;
      }
    }
  }

  &-origin-destination-container {
    margin-top: var(--standard-space);
    background-color: var(--ui-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--standard-space);
    min-width: 360px;
    border-radius: var(--border-radius-small);

    &-label {
      margin-bottom: unset;
      margin-right: var(--standard-space);
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  }

  &-legend-container {
    margin-bottom: var(--standard-space);
    &.hidden {
      display: none;
    }
  }

  &-data-menu-container {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: var(--standard-space);
    display: flex;
    justify-content: flex-end;
  }
}
