@import '../variables.scss';

.TripsDisplay {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;

  &-header {
    display: flex;
    padding: var(--standard-space);
    border-top-left-radius: var(--border-radius-large);
    border-top-right-radius: var(--border-radius-large);
    border: var(--border-width) solid var(--secondary-gray);
    font-size: var(--text-size-large);
    justify-content: space-between;
    align-items: center;
  }

  &-iternary-header {
    display: flex;
    padding: var(--standard-space);
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: var(--border-radius-large);
    border-top-right-radius: var(--border-radius-large);
    border: var(--border-width) solid var(--secondary-gray);
  }

  &-iternary {
    display: flex;
    align-items: center;
    gap: 6px;

    &-odPairs {
      color: var(--medium-gray);
      font-size: 14px;
      cursor: pointer;
    }

    &-slash {
      color: var(--medium-gray);
      font-size: 18px;
    }

    &-selected-header {
      color: var(----standard-text-color);
      font-size: 18px;
    }
  }

  &-buttons-block {
    display: flex;
    gap: 6px;
    align-items: center;

    &-ellipsis {
      cursor: pointer;
    }

    &-button {
      padding: 6px;
      border-radius: 3px;
      border: var(--border-width) solid var(--secondary-gray);
      color: var(----standard-text-color);
      background-color: var(--ui-background);
      cursor: pointer;

      &.selected {
        color: var(--blue-dark);
        border: var(--border-width) solid var(--blue-dark);
      }
    }
  }

  &-list {
    display: flex;
    gap: var(--standard-space);
    background-color: var(--ui-background);

    padding-left: var(--standard-space);
    padding-right: var(--standard-space);
    padding-bottom: var(--standard-space);
    flex-grow: 1;
    overflow: scroll;
    position: relative;
    border-bottom-left-radius: var(--border-radius-large);
    border-bottom-right-radius: var(--border-radius-large);
    border: var(--border-width) solid var(--secondary-gray);
    border-top: none;

    &-column {
      display: flex;
      flex-direction: column;
      height: fit-content;
      flex-grow: 1;
      min-width: 140px;

      &-header {
        position: sticky;
        top: 0;
        background-color: var(--ui-background);
        padding-top: var(--dropdown-spacing);
        padding-bottom: var(--dropdown-spacing);
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: var(--button-margin);
        white-space: nowrap;
        flex-shrink: 0;
        z-index: 1;
      }
    }

    &-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-top: var(--standard-space);
      gap: var(--dropdown-spacing);
    }

    &-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &.unhovered {
        opacity: 0.3;
      }
    }
  }
}
