@import '../../variables.scss';

.ItinerariesWeaknessOverview {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(100% - 2 * var(--standard-space));
  // This looks gross but accounts for all padding here and in its parent
  height: calc(var(--transit-travel-time-height));
  border-radius: var(--border-radius-large);
  justify-content: center;
  align-items: center;
  border: var(--border-width) solid var(--secondary-gray);
  background-color: var(--ui-background);
  padding: var(--standard-space);

  &-header {
    height: 15px;
    width: 100%;

    &.menu-primary-label {
      margin-bottom: unset;
    }
  }

  &-buttons-block {
    display: flex;
    gap: 6px;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    &-button {
      padding: 6px;
      border-radius: 3px;
      border: var(--border-width) solid var(--secondary-gray);
      color: var(----standard-text-color);
      background-color: var(--ui-background);
      height: 27px;
      font-size: 12px;
      cursor: pointer;

      &.selected {
        color: var(--blue-dark);
        border: var(--border-width) solid var(--blue-dark);
      }
    }
  }

  &-inputs-container {
    width: 100%;
    height: 60px;
    display: flex;
    gap: var(--standard-space);
    padding-left: var(--standard-space);
    padding-right: var(--standard-space);
  }

  &-chart-container {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  &-legend {
    height: 60px;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 0 var(--standard-space);

    &-item {
      display: flex;
      align-items: center;
      font-size: var(--text-size-extra-small);
      flex-wrap: nowrap;
      gap: var(--button-margin);
      width: calc(50% - var(--standard-space));
    }

    &-swatch {
      border-radius: 50%;
      height: var(--button-margin);
      width: var(--button-margin);
    }
  }

  .bar-text {
    font-size: 11px;
    fill: var(--standard-text-color);
  }

  // SVG styling
  .axis {
    &-text {
      font-size: var(--text-size-small);
      font-weight: 600;
    }
  }

  .axis .tick line {
    stroke: var(--secondary-gray);
    color: var(----standard-text-color);
  }

  .axis .domain {
    stroke: var(--secondary-gray);
  }

  .target-text {
    font-size: var(--text-size-small);
    font-weight: 600;
  }

  .dot {
    cursor: pointer;

    &.unhovered {
      opacity: 0.3 !important;
    }

    &.hovered {
      opacity: 1 !important;
      z-index: 100;
    }
  }
}

.chartTooltip {
  background-color: var(--standard-text-color);
}

.ScatterplotInput {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  &-input {
    display: flex;
    gap: var(--button-margin);
    margin-top: calc(var(--text-size-small) * 1.5);
  }

  input {
    border: var(--border-width) solid var(--secondary-gray);
    border-radius: var(--button-border-radius);
    flex-grow: 1;
  }

  &-label-container {
    display: flex;
    width: 100%;
    position: absolute;
  }

  &-label {
    flex-grow: 1;
    margin-bottom: var(--button-margin);
    font-size: var(--text-size-small);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
