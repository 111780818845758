@import '../variables.scss';

.MapZoom {
  display: flex;
  flex-direction: column;
  background-color: var(--ui-background);
  height: fit-content;
  width: fit-content;
  margin-right: var(--standard-space);
  border-radius: var(--border-radius-small);

  &-zoom-menu {
    position: absolute;
    left: calc(3rem + 1rem);
    top: calc(3rem * 3);
    .MenuContainer {
      min-width: unset !important;
    }
  }

  &-selections-menu {
    position: absolute;
    left: calc(3rem + 1rem);
    top: calc(3rem * 2);
  }

  &-zoom-button-container {
    display: flex;
    flex-direction: column;
    gap: var(--button-margin);
  }

  &-zoom-button {
    padding: var(--button-padding);
    border-radius: var(--border-radius-small);
    background-color: var(--secondary-gray);
    white-space: nowrap;
    cursor: pointer;
  }

  &-button {
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--secondary-gray);

    &:first-of-type {
      border-top-left-radius: var(--border-radius-small);
      border-top-right-radius: var(--border-radius-small);
    }

    &:last-of-type {
      border-bottom-left-radius: var(--border-radius-small);
      border-bottom-right-radius: var(--border-radius-small);
      border-bottom: none;
    }

    &.active {
      background-color: var(--selected-color);
      .MapZoom-icon-container {
        filter: var(--filter-ui-background);
      }
    }

    &:hover {
      background-color: var(--selected-color);
      .MapZoom-icon-container {
        filter: var(--filter-ui-background);
      }
    }

    &.disabled {
      .MapZoom-icon-container {
        filter: var(--filter-svg-medium-gray);
      }
      background-color: var(--secondary-gray);
      pointer-events: none;
    }

    &.selected {
      background-color: var(--selected-color);
      .MapZoom-icon-container {
        filter: var(--filter-ui-background);
      }
    }
  }

  &-dropdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--secondary-gray);
    width: 5rem;
  }

  &-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Dropdown {
    &-current {
      border: none;
      border-radius: 0;

      &-label {
        justify-content: center;
        font-weight: bold;
      }
    }

    &-border-helper-left {
      border: none;
    }

    &-border-helper-right {
      border: none;
    }

    &-list {
      border: var(--border-width) solid var(--secondary-gray);
      border-radius: var(--button-border-radius);
      width: 200%;
    }

    &.position-bottom {
      .Dropdown-list {
        margin-top: calc(var(--dropdown-height) + (var(--border-width) * 2));
        border-top: var(--border-width) solid var(--secondary-gray);
      }
    }
  }
}
